import axios from "axios"

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www=form-urlencoded'

export default {
    requestGet( url) {
        return new Promise((resolve ,reject)=>{
            axios.get(url).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    requestPost(url,params={}) {
        return new Promise((resolve ,reject)=>{
            axios.post(url,params).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err)
            })
        })
    }
}
