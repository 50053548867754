<template>
    <div>
        <div class="input-box">
            <input v-if="!disableInput" v-model="roomId" placeholder="请输入你的抢答房间号"/>
            <input v-model="name" placeholder="请输入你的姓名"/>
            <input v-model="group" placeholder="请输入你的组别"/>
            <button @click="toRoom">加入房间</button>
        </div>
    </div>
</template>

<script>
    // import {onCreateNotification} from "../../components/notification/notification.js"
    export default {
        name: "index",
        data() {
            return {
                disableInput: false,
                roomId: "",
                name: "",
                group: ""
            }
        },
        mounted() {

            this.roomId = this.$route.query['id']
            console.log(this.roomId==undefined)
            if (this.roomId!=""&&this.roomId!=undefined) {
                this.disableInput = true
            }
        },
        methods: {
            toRoom() {
                if (this.roomId && this.name && this.group) {
                    this.$router.push({path: "/room", query: {id: this.roomId, name: this.name, group: this.group}})
                }
                // else {
                //     // onCreateNotification()
                //     // this.notification = onCreateNotification({
                //     //         title:"dsds"}
                //     // )
                // }
            }
        }
    }
</script>

<style scoped>
    h2 {
        margin: 0 auto;
    }





</style>
