import Index from  "./pages/index/index"
import Room from  "./pages/room/room"
import Admin from  "./pages/admin/admin"
import Question from  "./pages/question/question"


export const  routes = [
    { path: '/', component: Index },
    { path: '/index', component: Index },
    { path: '/admin', component: Admin },
    { path: '/room', component: Room },
    { path: '/question', component: Question }
]
