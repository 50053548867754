<template>
    <div>
        <i class="icon-qr-code right" :style="'font-size: 40px;top: 20px;'+(!showQrCode?'color:#62bad5;':'')"
           @click="dealShowQrCode"/>
        <i class="icon-refresh right"
           :style="'font-size: 40px;top: 80px;'+(!showQrCode?'color:#62bad5;':'')"
           @click="reConnect"/>
        <i :class="(connectState!==-1?'icon-conn-success':'icon-conn-failed')+' left'"
           :style="'font-size: 40px;top: 20px;'+(connectState===0?'color:#d4d4d4;':connectState===1?'color:rgb(60, 129, 240);':'color:rgb(232, 59, 53);')"/>
        <div class="qr-code-box">
            <iframe :hidden="showQrCode" id="iframe"
                    :src="'https://hyz.cool/qrcode/?q='+encodeURIComponent('https://answer.hyz.cool/#/'+(roomId?'?id='+roomId:''))"
            />
        </div>

        <h2> {{roomId}} </h2>
        <div class="user-info">
            <span>{{group}}组</span>
            <span>{{name}}</span>
        </div>
        <div v-if="!showBeginAnswerBtn" class="welcome">
            <p>欢迎参与，请等待抢答</p>
            <p>收不到通知可点右上角刷新</p>
        </div>
        <button v-if="showBeginAnswerBtn" @click="answer">开始抢答({{time}})</button>
    </div>
</template>

<script>
    import Socket from "@/utils/socket";
    import {WEB_SOCKET_URL} from "@/utils/Constance";

    var MaxReConnectCount = 10
    var ReConnectCount = 0
    export default {
        name: "room",
        data() {
            return {
                showQrCode: true,
                roomId: "",
                name: "",
                group: "",
                socket: null,
                showBeginAnswerBtn: false,
                connectState: 0,
                time: 15
            }
        },
        mounted() {
            this.roomId = this.$route.query['id']
            this.name = this.$route.query['name']
            this.group = this.$route.query['group']
            if (this.roomId && this.name && this.group) {
                this.initSocket()
            } else {
                this.connectState = -1
                console.log("连接失败！")
            }
        },
        methods: {
            dealShowQrCode() {
                this.showQrCode = this.showQrCode ? false : true
            },
            initSocket() {
                if (ReConnectCount < MaxReConnectCount) {
                    this.socket = new Socket(WEB_SOCKET_URL + this.roomId).conn(
                        this.onSocketOpen,
                        this.onSocketStringMessage,
                        this.onSocketClose,
                        this.onSocketError)
                    ReConnectCount++
                }
            },

            onSocketOpen() {
                this.socket.send_conn()
                this.socket.send_join()
                this.connectState = 1
            },

            onSocketClose() {
                this.initSocket()
                this.connectState = -1
            },

            onSocketStringMessage(e) {
                console.log("onSocketStringMessage :", e)
                switch (e.type) {
                    case "normal":
                        switch (e.messageType) {
                            case "info":
                                var info = JSON.parse(e.message)
                                if (info.name && info.group) {
                                    this.userList.push({
                                        name: info.name,
                                        group: info.group
                                    })
                                }
                                break
                            case "begin":
                                this.showBeginAnswerBtn = true
                                if (this.k)
                                    clearInterval(this.k)
                                this.k = setInterval(() => {
                                    this.time--
                                    if (this.time == -1) {
                                        if (this.k)
                                            clearInterval(this.k)
                                        this.time = 15
                                        this.showBeginAnswerBtn = false
                                    }
                                }, 1000)
                                break
                            case "end":
                                if (this.k)
                                    clearInterval(this.k)
                                this.time = 15
                                this.showBeginAnswerBtn = false
                                break
                        }
                        console.log("onSocketStringMessage :", e.message)
                        break
                }
            },
            onSocketError() {
                this.initSocket()
                this.connectState = -1
            },
            answer() {
                this.socket.send_info(this.name, this.group)
                this.showBeginAnswerBtn = false
                if (this.k)
                    clearInterval(this.k)
                this.time = 15
            },
            reConnect() {
                ReConnectCount = 0
                this.initSocket()
            }
        }
    }
</script>

<style scoped>




    .user-info {
        font-family: fangsong;
    }

    .user-info span {
        margin: 0 10px;
    }


    .welcome {
        margin: 60px 0;
        letter-spacing: 10px;
        font-size: 35px;
        text-align: center;
        font-family: 华文新魏;
    }
</style>
