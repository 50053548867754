import http from "./http";

export const addQuestion=(data)=>{
   return  http.requestPost("/admin/answer/add",data)
}
export const listQuestions=()=>{
   return http.requestGet("/admin/answer/list")
}
export const delQuestion=(id)=>{
    return  http.requestGet("/admin/answer/del?id="+id)
}
export const updateQuestion=(id,data)=>{
    return http.requestPost("/admin/answer/update?id="+id,data)
}
