<template>
    <div class="footer">作者：李槟</div>
</template>

<script>
    export default {
        name: "footer"
    }
</script>

<style scoped>
.footer{
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: cadetblue;
    width: 95%;
    font-size: 10px;

}
</style>
