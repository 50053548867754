class Socket {
    constructor(url) {
        this.socket = new WebSocket(url);
    }
    conn(onOpen,
         onStringMessage,
         onAnswerMessage,
         onOfferMessage,
         onCandidateMessage,
         onClose,
         onError) {
        this.socket.onopen = () => onOpen()
        this.socket.onmessage = (data) => {
            //接收到的message数据类型为String 需要转为json格式
            let message = JSON.parse(data.data)
                onStringMessage(message)
        }
        this.socket.onclose = (e) => onClose(e)
        this.socket.onerror = (e) => onError(e)
        return this
    }

    send(message) {
        this.socket.send(message)
    }

    send_conn= ()=>  {
        this.send(JSON.stringify({//向服务器发送连接socket通知
            type: "connect",
            id: this.createUniqueId(),//用户唯一标识
            userInfo: JSON.stringify({name: "嘿嘿" + this.getRandom()}),
            message: "我连接上你啦",
            messageType: "text",
            maxCount: 100,//最大连接数
            time: new Date()
        }))
    }
    send_join=()=>  {
        this.sendMsg("join", "我加入啦", "text")
    }

    send_info=(name,group)=>  {
        this.sendMsg("normal", JSON.stringify({name,group}), "info")
    }
    send_begin_answer=()=>  {
        this.sendMsg("normal","", "begin")
    }
    send_end_answer=()=>  {
        this.sendMsg("normal","", "end")
    }

    sendString=(message)=>{
        this.sendMsg("normal",message,"text")
    }
    sendMsg=(type, message, messageType)=> {//封装发送信息方法
        if (this.socket) {//判断socket是否为空
            let msg = JSON.stringify({//Json 转 String
                type: type,
                message: message,
                messageType: messageType,
                time: new Date()
            })
            this.send(msg)//向服务器发送信息
        } else {
            console.log("socket is Null!")
        }
    }

    createUniqueId() {//创建唯一Id
        return this.getRandom() + new Date().getTime().toString() + this.getRandom()
    }

    getRandom() {//生成随机数
        let num = (Math.random() * 1000).toFixed(0).toString()
        return num.length == 3 ? num : num.length == 2 ? "1" + num : "12" + num
    }
}

export default Socket
