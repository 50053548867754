<template>
    <div>
        <i class="icon-qr-code right" :style="'font-size: 40px;top: 20px;'+(!showQrCode?'color:#62bad5;':'')"
           @click="dealShowQrCode"/>
        <i v-if="!showInputBox" class="icon-refresh right" :style="'font-size: 40px;top: 80px;'+(!showQrCode?'color:#62bad5;':'')"
           @click="reConnect"/>
        <i :class="(connectState!==-1?'icon-conn-success':'icon-conn-failed')+' left'" :style="'font-size: 40px;top: 20px;'+(connectState===0?'color:#d4d4d4;':connectState===1?'color:rgb(60, 129, 240);':'color:rgb(232, 59, 53);')"/>
        <div  class="qr-code-box">
            <iframe :hidden="showQrCode" id="iframe"
                    :src="'https://hyz.cool/qrcode/?q='+encodeURIComponent('https://answer.hyz.cool/#/'+(roomId?'?id='+roomId:''))"
            />
        </div>
        <div class="question-list" v-if="showQuestion">
            <div class="header-box">
                <button :disabled="currentIndex===0" @click="pre" class="btn-pre">上一题</button>
                <h3>第{{currentIndex+1}}题</h3>
                <button :disabled="currentIndex===questionList.length-1"  @click="next" class="btn-next">下一题</button>
            </div>
            <div class="question" >
                <p class="title">
                    {{questionList[currentIndex].title}}
<!--                    <span class="type" style="background: #0897c3" v-if="questionList[currentIndex].type==0">单选题</span>-->
<!--                    <span class="type" style="background: #dc4de5" v-if="questionList[currentIndex].type==1">判断题</span>-->
<!--                    <span class="type" style="background: #7d9533" v-if="questionList[currentIndex].type==2">填空题</span>-->
<!--                    <span class="type" style="background: #328927" v-if="questionList[currentIndex].type==3">简答题</span>-->
                </p>
                <div class="content" v-if="questionList[currentIndex].type==0" >
                    <p v-for="(value,pos) in JSON.parse(questionList[currentIndex].content)" :key="pos">{{'ABCDE'.split("")[pos]}}.{{value}}</p>
                </div>
                <button @click="showAnalysis">{{!isShowAnalysis?"显示":"隐藏"}}解析</button>
                <div class="analysis"  v-if="isShowAnalysis">
                    <p>【解析】：{{questionList[currentIndex].analysis?questionList[currentIndex].analysis:"暂无解析"}}</p>
                </div>
            </div>
        </div>

        <div class="input-box">
            <input v-if="showInputBox" v-model="roomId" placeholder="请输入你的抢答房间号"/>
            <button v-if="showInputBox" @click="manageRoom">管理房间</button>
            <div style="display: flex">
                <button v-if="!showInputBox" @click=" showQuestion= !showQuestion">{{showQuestion?"隐藏":"显示"}}题目</button>
                <button v-if="!showInputBox&&!beginAnswer" @click="begin" style="background: #20b5e7;color: white;border:none;">开始抢答</button>
                <button v-if="!showInputBox&&beginAnswer" @click="end" style="background: red;color: white;border:none;">结束抢答({{time}})</button>
                <button v-if="!showInputBox" @click="clear">清空屏幕</button>
            </div>
        </div>
        <div class="user-list" v-if="!showInputBox">
            <table class="user-list-item">
                <thead>
                <tr>
                <tr>
                    <td style="width: 190px;border:none;font-weight:600;"> 组别</td>
                    <td style="width: 190px;border:none;font-weight:600;"> 姓名</td>
                    <td style="width: 380px;border:none;font-weight:600;"> 时间</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in userList" :key="index">
                    <td>{{item.group}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.time}}</td>
                </tr>
                </tbody>
            </table>
        </div>
<!--        <div>{{onlineCount}}</div>-->
    </div>
</template>

<script>
    import Socket from "@/utils/socket";
    import {WEB_SOCKET_URL} from "@/utils/Constance";
    import {listQuestions} from "@/api/api";

    var MaxReConnectCount = 1000
    var ReConnectCount = 0
    export default {
        name: "admin",
        data() {
            return {
                showQrCode: true,
                showInputBox: true,
                beginAnswer: false,
                connectState:0,
                roomId: "",
                name: "",
                group: "",
                socket: null,
                userList: [],
                time: 15,
                onlineCount:0,
                questionList: [],
                currentIndex : 0,
                showQuestion:false,
                isShowAnalysis:false
            }
        },
        mounted() {
            this.getAllQuestions()
        },
        methods: {
            getAllQuestions() {
                listQuestions().then(res => {
                    this.questionList = res.data
                })
            },
            dealShowQrCode() {
                this.showQrCode = this.showQrCode ? false : true
            },
            initSocket() {
                if (ReConnectCount < MaxReConnectCount) {
                    this.socket = new Socket(WEB_SOCKET_URL + this.roomId).conn(
                        this.onSocketOpen,
                        this.onSocketStringMessage,
                        this.onSocketClose,
                        this.onSocketError)
                    ReConnectCount++
                }
            },

            onSocketOpen() {
                this.socket.send_conn()
                this.socket.send_join()
                this.connectState=1
                this.showInputBox = false
            },

            onSocketClose(e) {
                this.initSocket()
                this.connectState=-1
                console.log("重新连接 :", e)
            },

            onSocketStringMessage(e) {
                console.log("onSocketStringMessage :", e)
                switch (e.type) {
                    case "normal":
                        switch (e.messageType) {
                            case "info":
                                var info = JSON.parse(e.message)
                                if (info.name && info.group) {
                                    this.userList.push({
                                        name: info.name,
                                        group: info.group,
                                        time: e.time
                                    })
                                }
                                break
                        }
                        console.log("onSocketStringMessage :", e.message)
                        break

                    case "count":
                        console.log("onSocketStringMessage :", e.message)
                        var count = parseInt(e.message)
                        this.onlineCount =count
                        break
                }
            },
            onSocketError(e) {
                this.initSocket()
                this.connectState=-1
                console.log("onSocketError :", e)
            },
            manageRoom() {
                if (this.roomId) {
                    this.initSocket()
                }
            },
            begin() {
                this.socket.send_begin_answer()
                this.beginAnswer = true
                if (this.k) {
                    clearInterval(this.k)
                }
                this.k = setInterval(() => {
                    this.time--
                    if (this.time == -1) {
                        clearInterval(this.k)
                        this.time = 15
                        this.beginAnswer = false
                    }
                }, 1000)
            },
            end() {
                this.socket.send_end_answer()
                this.beginAnswer = false
                if (this.k) {
                    clearInterval(this.k)
                }
                this.time = 15
            },
            clear() {
                this.userList = []
            },
            reConnect(){
                ReConnectCount=0
                this.initSocket()
            },
            pre(){
                if(this.currentIndex>0){
                    this.currentIndex--
                    this.isShowAnalysis=false
                }
            },

            next(){
                if(this.currentIndex<this.questionList.length-1){
                    this.currentIndex++
                    this.isShowAnalysis=false
                }
            },
            showAnalysis(){
                this.isShowAnalysis=! this.isShowAnalysis
            }
        }
    }
</script>

<style scoped>
    i{

        cursor: pointer;
    }
    .icon-qr-code:hover,
    .icon-refresh:hover{
        color: #62bad5;
    }
    .right {
        position: absolute;
        right: 20px;
    }
.left{
    position: absolute;
    left: 20px;

}
    h2 span {
        text-decoration: underline;
        margin: 0 10px;
    }



    .input-box button:active {
        opacity: 0.6;
    }

    .user-list-item {
        width: fit-content;
        box-shadow: 0 0 10px 1px #d4d4d4;
        padding: 20px;
        margin: 0 auto;
    }

    .user-list-item span {
        margin: 10px;
    }

    td {
        border: 1px solid #d4d4d440;
        height: 60px;
        font-size: 20px;
    }



    .question-list {
        box-shadow: 0 0 10px 1px #d4d4d48f;
        padding: 10px;
        margin: 0 auto;
        margin-top: 40px;
        width: 1000px;
    }

    .question-list .question {
        box-shadow: 0 0 10px 1px #d4d4d48f;
        padding: 10px;
        padding-left: 20px;
        margin: 10px 0;
        text-align: left;
    }

    .question-list .question .title  {
        font-weight: bolder;
        font-size: 33px;
        display: flex;align-items: center;
    }
    .question-list .question .title .type {
        border-radius: 3px;
        padding: 5px 7px;
        color: white;
        font-size: 13px;
               font-weight: 100;
    }
    .question-list .question .content  {
        padding-left: 15px ;
        font-size: 25px;
    } .question-list .question .analysis {
        font-size: 25px;
    }

    .question-list .header-box  {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
    }

    .question-list .header-box button {
        padding: 5px 10px;
        font-size: 14px;
        margin: 10px;
        margin-right: 20px;
        color:white;
        box-shadow: none;
        border:none;
    cursor: pointer;
    }

    .question-list .header-box  button:active {
        opacity: 0.6;
    }
    .question-list .header-box .btn-pre {
        background: red;

    }

    .question-list .header-box .btn-next {
        background: #44cef1;
    }
    .question-list .question button{

        background: #006956;

        padding:  10px;
        font-size: 14px;
        margin: 10px;
        margin-right: 20px;
        color:white;
        box-shadow: none;
        border:none;
        cursor: pointer;
    }
    .question-list .question button:active {
        opacity: 0.6;
    }
</style>
