<template>
    <div class="input-box">

        <div class="select">
            <span>请选择问题类型</span>
            <select @change="changeType" v-model="type">
                <option :value="0">选择题</option>
                <option :value="1">判断题</option>
                <option :value="2">填空题</option>
                <option :value="3">简答题</option>
            </select>
        </div>
        <input v-model="title" placeholder="请输入问题"/>
        <div v-if="type===0&&contentSelectItem.length>0" class="select-content-box">
            <div v-for="(item,index)  in contentSelectItem" :key="index" class="line">
                <input @input="e=>inputItem(e,index)" :value="item" placeholder="请输入选项"/>
                <i class="icon-decrease" @click="decreaseSelectItem(index)" style="font-size: 40px;color:red;"/>
            </div>

        </div>

        <i v-if="type===0" class="icon-add" @click="addSelectItem" style="font-size: 40px;color:#03a9f4;margin:20px;"/>

        <input v-model="analysis"  placeholder="请输入解析"/>
        <button v-if="title&&!isUpdate" @click="addQuestion" class="btn-add">上传本题</button>
        <button v-if="title&&isUpdate" @click="doUpdateQuestion" class="btn-add">更新本题</button>
        <button v-if="added" @click="addNext" class="btn-add-next">添加下一题</button>
        <span>{{canUploadText}}</span>

        <div class="question-list">
            <h3>共{{questionList.length}}题</h3>
            <div class="question" v-for="(item,index ) in questionList" :key="index">

                <p class="title">
                    {{item.title}}
<!--                    <span class="type" style="background: #0897c3" v-if="item.type==0">选择题</span>-->
<!--                    <span class="type" style="background: #dc4de5" v-if="item.type==1">判断题</span>-->
<!--                    <span class="type" style="background: #7d9533" v-if="item.type==2">填空题</span>-->
<!--                    <span class="type" style="background: #328927" v-if="item.type==3">简答题</span>-->
                </p>
                <p class="content" v-if="item.type==0||item.type==1">
                    {{item.type==0||item.type==1?JSON.parse(item.content):""}}
                </p>
                <p class="content" >
                    {{item.analysis}}
                </p>

                <div class="btn">
                    <button @click="update(item)" class="btn-update">修改</button>
                    <button @click="deleteQuestion(item)" class="btn-del">删除</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {addQuestion, delQuestion, listQuestions, updateQuestion} from "@/api/api";

    export default {
        name: "question",
        data() {
            return {
                question:{},
                type: 0,//0,选择,1，判断,2，填空，3，简答
                title: "",
                content: "",
                contentSelectItem: [],
                contentJudgeItem: ["对", "错"],
                canUploadText: "",
                questionList: [],
                analysis:"",
                isUpdate:false,
                added:false
            }
        },
        mounted() {
            this.getAllQuestions()
        },
        methods: {
            getAllQuestions() {
                listQuestions().then(res => {
                    this.questionList = res.data
                    console.log(res)
                }).catch(err=>{

                    console.log(err)
                })
            },
            changeType(e) {
                console.log(e)
            },
            inputItem(e, index) {
                this.contentSelectItem[index] = e.target.value
                console.log(this.contentSelectItem)
            },
            addSelectItem() {
                this.contentSelectItem.push("")
            },
            decreaseSelectItem(index) {
                console.log(index)
                this.contentSelectItem.splice(index, 1)
                console.log(this.contentSelectItem)
            },
            verifyContent() {
                var verify = true
                if (this.type === 0) {
                    if (this.contentSelectItem.length === 0) {
                        return false
                    } else
                        this.contentSelectItem.forEach(item => {
                            if (item == "") {
                                verify = false
                                return
                            }
                        })
                }
                return verify
            },
            addQuestion() {
                if (this.verifyContent()) {
                    this.canUploadText = "内容完整，可以上传啦！"
                    this.doUpload()
                    setTimeout(() => {
                        this.canUploadText = ""
                    }, 3000)
                } else {
                    this.canUploadText = "内容不完整，不可以上传！"
                    setTimeout(() => {
                        this.canUploadText = ""
                    }, 3000)

                }
            },
            doUpload() {
                var content = ""
                if (this.type === 0) {
                    content = JSON.stringify(this.contentSelectItem)
                } else if (this.type === 1) {
                    content = JSON.stringify(this.contentJudgeItem)
                } else {
                    content = "" + this.type
                }
                addQuestion({title: this.title, type: this.type, content,analysis:this.analysis}).then(res => {
                    console.log(res)
                    this.added=true
                    this.getAllQuestions()
                })
            },
            doUpdateQuestion() {
                if(!this.question.id&&!this.isUpdate)return
                var content = ""
                if (this.type === 0) {
                    content = JSON.stringify(this.contentSelectItem)
                } else if (this.type === 1) {
                    content = JSON.stringify(this.contentJudgeItem)
                } else {
                    content = "" + this.type
                }
                updateQuestion(this.question.id,{title: this.title, type: this.type, content,analysis:this.analysis}).then(res => {
                    console.log(res)
                    this.added=true
                    this.getAllQuestions()
                })
            },
            update(item){
                this.question = item
                this.isUpdate  =true
                this.title = item.title
                this.type = item.type
                this.analysis=item.analysis
                if(this.type==0){
                    this.contentSelectItem = JSON.parse(item.content)
                }
                // updateQuestion(item.id)
            },
            deleteQuestion(item){
                delQuestion(item.id).then(res=>{
                    console.log(res)
                    this.getAllQuestions()
                })
            },
            addNext(){
                this.title=""
                this.content=""
                this.contentSelectItem=[]
                this.type=0
                this.isUpdate=false
                this.added=false
                this.analysis=""
                this.question={}
            }

        }
    }
</script>

<style scoped>
    .input-box input {
        width: 400px;
    }

    .select {
        display: flex;
        font-size: 19px;
        justify-content: center;
        align-items: center;
        margin: 20px;
        box-shadow: 0 0 10px 1px #d4d4d48f;
        padding: 15px;
    }

    .select span {
        margin-right: 10px;
        color: #777777;
    }

    .select select {
        outline: none;
        border-radius: 4px;
        width: 90px;
        font-size: 19px;
        padding: 5px;
        border: none;
        background: #20b0e7;
        color: white;

    }

    .select select option {
        outline: none;
        border: none;
    }

    .select select option:hover {
        background: white;
        color: #20b0e7;
    }

    .select-content-box {
        box-shadow: 0 0 10px 1px #d4d4d48f;
        padding-right: 10px;
        margin: 20px;
    }

    .line {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .line input {
        padding: 10px;
        width: 350px;
        box-shadow: none;
        border-bottom: 1px solid #888888;
    }

    .btn-add {
        background: #b581c7;
        color: white;
    }

    .question-list {
        box-shadow: 0 0 10px 1px #d4d4d48f;
        padding: 10px;
    }

    .question-list .question {
        box-shadow: 0 0 10px 1px #d4d4d48f;
        padding: 10px;
        padding-left: 20px;
        margin: 10px 0;
        text-align: left;
    }

    .question-list .question .title .type {
        border-radius: 3px;
        padding: 5px 7px;
        color: white;
        font-size: 13px;
    }

    .question-list .question .btn {
        display: flex;
    }

    .question-list .question .btn button {
        padding: 5px 10px;
        font-size: 14px;
        margin: 0;
        margin-right: 20px;
        color:white;
    }

    .question-list .question .btn .btn-del {
        background: red;
    }

    .question-list .question .btn .btn-update {
        background: #44cef1;
    }
</style>
